import React, { useEffect, useRef } from 'react'
import { object } from 'prop-types'
import { Breadcrumb, Row, Col, List } from 'antd'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { injectIntl } from 'react-intl'
import moment from 'moment'
import throttle from 'lodash/throttle'

import HtmlContent from 'components/HtmlContent'
import SEO from 'components/seo'
import { BREAKPOINT_DESKTOP } from 'constants/site'
import { getHomeSlug } from 'utils'
import styles from './post.module.less'

const PostTemplate = ({ intl, data, pageContext }) => {
  const siderRef = useRef(null)
  const hrRef = useRef(null)
  const {
    post: { frontmatter, html, excerpt },
    postList
  } = data
  const { previous, next } = pageContext
  const homeSlug = getHomeSlug(pageContext)
  const handleScroll = throttle(e => {
    if (!siderRef.current) return

    const siderStyle = siderRef.current.style
    const hrRect = hrRef.current.getBoundingClientRect()
    const winHeight = window.innerHeight

    if (hrRect.top > 0 && hrRect.bottom <= winHeight) {
      // when bottom hr scroll into view
      siderStyle.top = 'auto'
      siderStyle.bottom = 0
      siderStyle.transform = `translateY(${hrRect.bottom -
        window.innerHeight}px)`
    } else {
      siderStyle.bottom = ''
      siderStyle.transform = ''
    }
  })

  useEffect(() => {
    if (window.innerWidth >= BREAKPOINT_DESKTOP) {
      window.addEventListener('scroll', handleScroll)
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  return (
    <>
      <SEO
        title={frontmatter.title}
        description={frontmatter.description || excerpt}
      />
      <div className={styles.postPage}>
        <div className="container">
          <Breadcrumb className={styles.breadcrumb}>
            <Breadcrumb.Item>
              <Link to="/">
                {intl.formatMessage({ id: 'breadcrumb.home' })}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to={`${homeSlug}news`}>
                {intl.formatMessage({ id: 'breadcrumb.news' })}
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>{frontmatter.title}</Breadcrumb.Item>
          </Breadcrumb>
          <Row gutter={32}>
            <Col sm={24} lg={16}>
              <article>
                <h1>{frontmatter.title}</h1>
                <p className={styles.date}>
                  <span>
                    {moment(new Date(frontmatter.date)).format(
                      intl.formatMessage({ id: 'date.format' })
                    )}
                  </span>
                </p>
                {frontmatter.featuredImage && (
                  <div className={styles.featuredImg}>
                    <Img
                      fluid={frontmatter.featuredImage.childImageSharp.fluid}
                    />
                  </div>
                )}
                <HtmlContent className={styles.content} html={html} />
              </article>
              <hr ref={hrRef} />
              <ul className={styles.bottomNav}>
                <li>
                  {previous && (
                    <Link to={previous.fields.slug} rel="prev">
                      ← {intl.formatMessage({ id: 'post.nav.prev' })}
                    </Link>
                  )}
                </li>
                <li>
                  {next && (
                    <Link to={next.fields.slug} rel="next">
                      {intl.formatMessage({ id: 'post.nav.next' })} →
                    </Link>
                  )}
                </li>
              </ul>
            </Col>
            <Col sm={24} lg={8}>
              <aside ref={siderRef} className={styles.sider}>
                <List
                  ref={siderRef}
                  bordered
                  header={
                    <h2>
                      <Link to={`${homeSlug}news`}>
                        {intl.formatMessage({ id: 'postList.title' })}
                      </Link>
                    </h2>
                  }
                  dataSource={postList.edges}
                  renderItem={({ node: { fields, frontmatter } }) => (
                    <List.Item>
                      <Link to={fields.slug}>
                        <p>
                          {moment(new Date(frontmatter.date)).format(
                            intl.formatMessage({ id: 'date.format' })
                          )}
                        </p>
                        <h3 title={frontmatter.title}>{frontmatter.title}</h3>
                      </Link>
                    </List.Item>
                  )}
                />
              </aside>
            </Col>
          </Row>
        </div>
      </div>
    </>
  )
}

PostTemplate.propTypes = {
  intl: object.isRequired,
  data: object.isRequired,
  pageContext: object.isRequired
}

export default injectIntl(PostTemplate)

export const pageQuery = graphql`
  query BlogPostBySlug($locale: String!, $slug: String!) {
    site {
      siteMetadata {
        title
        author
      }
    }
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      excerpt
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    postList: allMarkdownRemark(
      filter: {
        fields: { locale: { eq: $locale } }
        fileAbsolutePath: { regex: "//news//" }
      }
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date
          }
        }
      }
    }
  }
`
